import React, { useContext, useEffect, useState, useRef } from "react";
import { AuthContext } from "../../contexts/auth.jsx";
import LeftBar_Item from "../../components/leftbar_item/index.jsx";
import { Item_Chat } from "../../components/item_chat/index.jsx";
import useSound from 'use-sound';
import boopSfx from '../../assets/music/notificacao.mp3';
import { useNavigate } from "react-router-dom";
import {
  api,
  buscaChamadoAbertoHistorico,
  atribuirSuporte,
  buscarNovasMensagens,
  enviarMensagem,
  enviarComentario,
  buscaClientes,
  alterarCliente,
  encerrarSuporte,
  buscaTecnicos,
  alterarTecnico,
  buscarHistoricoMensagens,
  consultaCategoria1,
  consultaCategoria2,
  consultaCategoria3,
  alterarCategoria,
  buscaClienteTelefone,
  abrirChamadoWhatsapp,
  consultaNovasMensagens
} from '../../services/api.jsx';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import './style.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Typeahead } from 'react-bootstrap-typeahead';
import copy from "copy-to-clipboard";

function Chat() {
  const { logout, setLoading, user } = useContext(AuthContext);
  const navigate = useNavigate();

  const MySwal = withReactContent(Swal);
  const aboutSection = useRef(null);

  const [comentario, setComentario] = useState();
  const [aberto, setAberto] = useState([]);
  const [aberto_seta, setAbertoSeta] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [ematendimento_seta, setEmAtendimentoSeta] = useState('');
  const [ematendimentoo_seta, setEmAtendimentoOSeta] = useState('');
  const [finalizado_seta, setFinalizadoSeta] = useState('');
  const [aberto_seta_class, setAbertoSetaClass] = useState('bx bx-minus');
  const [ematendimento_seta_class, seteEmAtendimentoSetaClass] = useState('bx bx-minus');
  const [ematendimentoo_seta_class, seteEmAtendimentoOSetaClass] = useState('bx bx-minus');
  const [finalizado_seta_class, seteFinalizadoSetaClass] = useState('bx bx-minus');
  const [clickedItem, setClickedItem] = useState({ nomecliente: 'undefined' });
  const [mensagens, setMensagens] = useState([]);
  const [idMensagem, setIdMensagem] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showModalCliente, setShowModalCliente] = useState(false);
  const [showModalCategoria, setShowModalCategoria] = useState(false);
  const [showModalTransferirSuporte, setShowModalTransferirSuporte] = useState(false);
  const [showModalAbrirChamadoWhatsapp, setShowModalAbrirChamadoWhatsapp] = useState(false);
  const [selectCliente, setSelectCliente] = useState();
  const [selectTransferirSuporte, setSelectTransferirSuporte] = useState();
  const [clientes, setClientes] = useState();
  const [tecnicos, setTecnicos] = useState();
  const [countMensagemEnviada, setCountmensagemEnviada] = useState(1);
  const [notificacaoCount, setNotificacaoCount] = useState(0);
  const [clienteTelefone, setClienteTelefone] = useState([]);
  const [selectClienteTelefone, setSelectClienteTelefone] = useState([]);
  const [categoria1, setCategoria1] = useState([]);
  const [categoria2, setCategoria2] = useState([]);
  const [categoria3, setCategoria3] = useState([]);
  const [exibeCombo, setExibeCombo] = useState(true);
  const [categoria2Disabled, setCategoria2Disabled] = useState(true);
  const [categoria3Disabled, setCategoria3Disabled] = useState(true);
  const [selectCategoria1, setSelectCategoria1] = useState([]);
  const [selectCategoria2, setSelectCategoria2] = useState([]);
  const [selectCategoria3, setSelectCategoria3] = useState([]);
  const [disabledButtonCategoria, setDisabledButtonCategoria] = useState(false);
  const [contadorErro, setContadorErro] = useState(0);
  const [numeroTelefone, setNumeroTelefone] = useState('');

  const [play] = useSound(boopSfx);

  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("Browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
    const token = localStorage.getItem('token');
    if (token) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
    }
    else {
      navigate("/login");
    }
    populaClientes();
    populaTecnicos();
    populaClienteTelefone();
    buscaChamadoAbertoHistoricoT();
    consultaCategoria1F();
    setLoading(false);
  }, []);
  useEffect(() => {
    if ((clickedItem.nomecliente !== 'undefined')) {
      var element = document.getElementById(clickedItem.id);
      if ((clickedItem.status == 'Em Atendimento')) {
        if ((clickedItem.user == user.id) || (!element.classList.contains('bg-warninga'))) {
          element.classList.add('ativo');
        }
      }
      else {
        element.classList.add('ativo');
      }

    }

    let intervalId = setInterval(() => {
      buscaChamadoAbertoHistoricoT();
    }, 5000);
    return () => {
      clearInterval(intervalId);
    }
  }, [aberto, clickedItem]);
  useEffect(() => {
    let intervalId = setInterval(async () => {
      if (clickedItem.nomecliente !== 'undefined') {
        await buscarNovasMensagensT(clickedItem.id, idMensagem);
      }
    }, 3000);
    return () => {
      clearInterval(intervalId);
    }
  }, [mensagens, idMensagem, clickedItem]);
  useEffect(() => {
    if (clickedItem.nomecliente !== 'undefined') {
      rolarChat();
    }
  }, [mensagens]);
  useEffect(() => {
    if (selectCategoria1.length != 0) {
      consultaCategoria2F(selectCategoria1);
    }
    else {
      setDisabledButtonCategoria(true);
      setSelectCategoria2([]);
      setCategoria3([]);
      setCategoria3Disabled(true);
      setCategoria2Disabled(true);
      setSelectCategoria3([]);
      setCategoria2([]);
    }
  }, [selectCategoria1]);
  useEffect(() => {
    if (selectCategoria2.length != 0) {
      consultaCategoria3F(selectCategoria2);
    }
    else {
      setDisabledButtonCategoria(true);
      setCategoria3([]);
      setCategoria3Disabled(true);
      setSelectCategoria3([]);
    }
  }, [selectCategoria2]);
  useEffect(() => {
    if (selectCategoria2.length != 0) {
      setDisabledButtonCategoria(false);
    }
  }, [selectCategoria3]);

  const buscaChamadoAbertoHistoricoT = async () => {
    try {
      const response = await buscaChamadoAbertoHistorico();
      setAberto(response.data);

      const difference = [
        ...getDifference(aberto, response.data),
        ...getDifference(response.data, aberto)
      ];

      difference.map((item) => {
        if (notificacaoCount === 1) {
          var options = {
            body: item.nomecliente,
            icon: 'https://cdn-icons-png.flaticon.com/512/891/891448.png',
            dir: 'ltr',
          };
          new Notification('Novo Atendimento aguardando', options);
          play();
        }
        setNotificacaoCount(1);
      });
      if (clickedItem.nomecliente !== 'undefined') {
        setClickedItem(response.data.find((item) => item.id === clickedItem.id));
      }
      setContadorErro(0);
    } catch (err) {
      if (err.code == 'ERR_NETWORK') {
        setContadorErro(contadorErro + 1);
        if (contadorErro > 10) {
          MySwal.fire({
            title: <p>Erro de comunicação com o servidor</p>,
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: 'OK',
          })
        }
      }
      if (err.response.status === 401) {
        logout();
      }
    }
  }
  const getDifference = (array1, array2) => {
    return array1.filter(object1 => {
      return !array2.some(object2 => {
        return object1.id === object2.id;
      });
    });
  }

  let fetchingNewMessages = false;
  const buscarNovasMensagensT = async (suporte_id, mensagem_id) => {
    if (fetchingNewMessages) return; // Se já estiver buscando, saia

    fetchingNewMessages = true; // Defina o semáforo como verdadeiro

    try {
      const response = await buscarNovasMensagens(suporte_id, mensagem_id, user.id);
      if (response.data.length !== 0) {
        setIdMensagem(response.data[response.data.length - 1].id);
        setMensagens(prevState => [...prevState, ...response.data]);
      }
    } catch (err) {
      // Tratar o erro se necessário
    } finally {
      fetchingNewMessages = false; // Defina o semáforo como falso após a conclusão
    }
  }
  const rolarChat = () => {
    aboutSection.current?.scrollIntoView({
      behavior: "smooth",
      block: "end"
    });
  }
  const HandleLogout = () => {
    logout();
  }
  const hideShowAbertoSeta = (e) => {
    setAbertoSeta(aberto_seta === '' ? 'd-none' : '');
    setAbertoSetaClass(aberto_seta_class === 'bx bx-minus' ? 'bx bx bx-plus' : 'bx bx-minus');
  }
  const hideShowematendimentoSeta = (e) => {
    setEmAtendimentoSeta(ematendimento_seta === '' ? 'd-none' : '');
    seteEmAtendimentoSetaClass(ematendimento_seta_class === 'bx bx-minus' ? 'bx bx bx-plus' : 'bx bx-minus');
  }
  const hideShowematendimentoOSeta = (e) => {
    setEmAtendimentoOSeta(ematendimentoo_seta === '' ? 'd-none' : '');
    seteEmAtendimentoOSetaClass(ematendimentoo_seta_class === 'bx bx-minus' ? 'bx bx bx-plus' : 'bx bx-minus');
  }
  const hideShowFinalizadoSeta = (e) => {
    setFinalizadoSeta(finalizado_seta === '' ? 'd-none' : '');
    seteFinalizadoSetaClass(finalizado_seta_class === 'bx bx-minus' ? 'bx bx bx-plus' : 'bx bx-minus');
  }
  const buscarHistoricoMensagensT = async (suporte_id) => {
    try {
      const response = await buscarHistoricoMensagens(suporte_id, user.id);

      setMensagens(response.data);
      if (response.data.length !== 0) {
        setIdMensagem(response.data[response.data.length - 1].id);
      }
    } catch (err) {

    }
  }
  const handlerClickedItem = async (e) => {
    setClickedItem(e);
    if (clickedItem.id !== e.id) {
      await buscarHistoricoMensagensT(e.id);
      var element1 = document.getElementById('user-chat');
      element1.classList.add('user-chat-show');
      if (clickedItem.nomecliente !== 'undefined') {
        var element1 = document.getElementById(clickedItem.id);
        element1.classList.remove('ativo');
      }
      var element = document.getElementById(e.id);
      if (e.tecnico_id == user.id) {
        element.classList.remove('bg-warninga');
        element.classList.add('ativo');
      }


      setSelectCategoria1([{ label: e.tcategoria1, id: e.tidcategoria1 }]);
      if (e.tcategoria2 != null) {
        setSelectCategoria2([{ label: e.tcategoria2.substring(2), id: e.tidcategoria2 }]);
      }
      if (e.tcategoria3 != null) {
        setSelectCategoria3([{ label: e.tcategoria3.substring(2), id: e.tidcategoria3 }]);
      }

      // buscarHistoricoMensagensT(e.id, user.id);
      setComentario(e.comentario);
      setMensagens([]);
      setCountmensagemEnviada(1);
      setIdMensagem(0);
      // buscarNovasMensagensT(e.id, 0);
    }
  }
  const handlerAtribuir = (e) => {
    MySwal.fire({
      title: <p>Deseja ser atribuído ao atendimento:</p>,
      text: clickedItem.nomecliente + '\n' + clickedItem.operador,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#28a745',
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await atribuirSuporte(clickedItem.id, user.id);
            buscaChamadoAbertoHistoricoT();
          }
          catch (err) {
            MySwal.fire({
              title: err.code.status,
              text: err.response
            });
          }
        }
      })
  }
  const handlerRemoveChat = () => {
    var element1 = document.getElementById('user-chat');
    element1.classList.remove('user-chat-show');
    var element = document.getElementById(clickedItem.id);
    element.classList.remove('ativo');
    setClickedItem({ nomecliente: 'undefined' });
  }
  const handlerEnviarMensagem = async (e) => {
    e.preventDefault();
    if (mensagem !== '') {
      setMensagem('');
      var dataAtual = new Date();
      var horas = dataAtual.getHours();
      var minutos = dataAtual.getMinutes();
      var dia = dataAtual.getDate();
      var mes = (dataAtual.getMonth() + 1);
      var ano = dataAtual.getFullYear();
      var dataCompleta = dia + "/" + mes + "/" + ano + " " + horas + ":" + minutos;
      var horaCompleta = horas + ":" + minutos;
      setMensagens(prevState => [...prevState, {
        data: dataCompleta,
        dataf: horaCompleta,
        enviado: 'T',
        id: 'X' + countMensagemEnviada,
        mensagem: mensagem,
        operador: clickedItem.operador,
        suporte_id: clickedItem.id,
        tecnico_id: user.id,
        tecniconome: user.nome
      }]);
      setCountmensagemEnviada(countMensagemEnviada + 1);
      const response = await enviarMensagem(clickedItem.id, mensagem, user.id, 'T');
      buscarNovasMensagensT(clickedItem.id, idMensagem);
    }
  }
  const handlerComentario = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
  }
  const handlerOpenModalCliente = () => setShowModalCliente(true);
  const handlerCloseModalCliente = () => setShowModalCliente(false);
  const handlerOpenModalTransferirSuporte = () => {
    populaTecnicos();
    setShowModalTransferirSuporte(true)
  };
  const handlerCloseModalTransferirSuporte = () => setShowModalTransferirSuporte(false);
  const handlerCloseModalAbrirChamadoWhatsapp = () => setShowModalAbrirChamadoWhatsapp(false);
  const handlerOpenModalCategoria = () => setShowModalCategoria(true);
  const handlerCloseModalCategoria = () => setShowModalCategoria(false);
  const handlerEnviarComentario = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (comentario !== '') {
      const response = await enviarComentario(comentario, clickedItem.id, user.id);
      if (response.status === 201) {
        MySwal.fire({
          title: <p>Comentário Inserido com sucesso!</p>,
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'OK',
        })
      }
    }
    handleCloseModal();
  }
  const handlerAlterarCliente = async (e) => {
    e.preventDefault();
    const response = await alterarCliente(selectCliente['selected'][0].id, clickedItem.id);
    if (response.status === 200) {
      MySwal.fire({
        title: <p>Cliente alterado com sucesso!</p>,
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'OK',
      })
      buscaChamadoAbertoHistoricoT();
      handlerCloseModalCliente();
    }
  }
  const populaClientes = async () => {
    const response = await buscaClientes();
    if (response.status === 200) {
      setClientes(response.data);
    }
  }
  const populaClienteTelefone = async () => {
    const response = await buscaClienteTelefone();
    if (response.status === 200) {
      setClienteTelefone(response.data);
    }
  }
  const handlerEncerrarSuporte = async () => {
    MySwal.fire({
      title: <p>Deseja encerrar o atendimento?</p>,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#28a745',
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await encerrarSuporte(clickedItem.id, 'T');
            buscaChamadoAbertoHistoricoT();

            MySwal.fire({
              icon: "success",
              text: "Finalizado com sucesso!",
              confirmButtonColor: '#28a745',
              confirmButtonText: 'OK!',
            });
          }
          catch (err) {
            MySwal.fire({
              title: err.code.status,
              text: err.response
            });
          }
        }
      })
  }
  const handlerInfoPC = async () => {
    MySwal.fire({
      title: <p>{clickedItem.nomePc}</p>,
      html: "<pre>" + clickedItem.processador + "\n" + clickedItem.ip + "\n RAM: " + clickedItem.memRam +
        "\n\n" + clickedItem.disco + "</pre>",
      icon: 'none',
      showCancelButton: false,
      confirmButtonColor: '#28a745',
      confirmButtonText: 'Ok',
      customClass: {
        popup: 'format-pre'
      }
    });
  }
  const populaTecnicos = async (t = '') => {
    const response = await buscaTecnicos(t);
    if (response.status === 200) {
      setTecnicos(response.data.filter((item) => item.id != 1));
    }
  }
  const handlerTransferirSuporte = async (e) => {
    e.preventDefault();
    const response = await alterarTecnico(selectTransferirSuporte['selected'][0].id, clickedItem.id);
    if (response.status === 200) {
      MySwal.fire({
        title: <p>Atendimento transferido com sucesso!</p>,
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'OK',
      })
      buscaChamadoAbertoHistoricoT();
      handlerCloseModalTransferirSuporte();
    }
  }
  const handlerAlterarCategoria = async (e) => {
    e.preventDefault();
    let id2 = null;
    let id3 = null;
    for (let i = 0; i < selectCategoria2.length; i++) {
      id2 = selectCategoria2[i].id;
    }
    for (let i = 0; i < selectCategoria3.length; i++) {
      id3 = selectCategoria3[i].id;
    }

    const response = await alterarCategoria(selectCategoria1[0].id, id2, id3, clickedItem.id);
    if (response.status === 200) {

      handlerCloseModalCategoria();
      MySwal.fire({
        title: <p>Categoria alterada com sucesso!</p>,
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'OK',
      })
    }
  }
  const consultaCategoria1F = async () => {
    var response = await consultaCategoria1();
    setCategoria1(response.data);
  }
  const consultaCategoria2F = async (id) => {
    var response = await consultaCategoria2(id[0].id);
    if (response.data.length != 0) {
      setCategoria2(response.data);
      setCategoria2Disabled(false);
      setDisabledButtonCategoria(true);
    }
    else {
      setDisabledButtonCategoria(false);
      setCategoria2([]);
      setCategoria2Disabled(true);
    }
    setCategoria3([]);
    setCategoria3Disabled(true);
    setSelectCategoria3([]);
  }
  const consultaCategoria3F = async (id) => {
    var response = await consultaCategoria3(id[0].id);
    if (response.data.length != 0) {
      setCategoria3(response.data);
      setCategoria3Disabled(false);
      setDisabledButtonCategoria(true);
    }
    else {
      setDisabledButtonCategoria(false);
      setCategoria3([]);
      setCategoria3Disabled(true);
    }
  }
  const copiarTexto = (texto) => {
    copy(texto);
  }
  const handlerAbrirChamadoWhatsapp = async (e) => {
    e.preventDefault();
    if (exibeCombo) {
      await abrirChamadoWhatsapp(selectClienteTelefone[0].id, user.id);
    }
    else {
      await abrirChamadoWhatsapp('55' + numeroTelefone + '@s.whatsapp.net', user.id);
    }

    setShowModalAbrirChamadoWhatsapp(false);
    setSelectClienteTelefone([]);
    setExibeCombo(true);
  }

  const [naoLidas, setNaoLidas] = useState({});
  const timeoutRef = useRef(null);
  const [isVerifying, setIsVerifying] = useState(false);

  const abertoRef = useRef(aberto);

  useEffect(() => {
    abertoRef.current = aberto;
    if (aberto && aberto.length > 0 && !isVerifying) {
      setIsVerifying(true);
      verificaMensagensLidasParaTodos();
    }
  }, [aberto]);

  const verificaMensagensLidasParaTodos = async () => {
    const novasNaoLidas = {};

    for (const item of abertoRef.current) {
      if (item.status === "Em Atendimento") {
        const response = await consultaNovasMensagens(item.id);
        if (response.status === 200 && response.data.count > 0) {
          novasNaoLidas[item.id] = response.data.count;
          play();
        } else {
          novasNaoLidas[item.id] = 0;
        }
      }
    }

    setNaoLidas(novasNaoLidas);

    timeoutRef.current = setTimeout(verificaMensagensLidasParaTodos, 8000);
  };

  return (
    <>
      <div className="layout-wrapper d-lg-flex">
        <div className="side-menu flex-lg-column">
          <div className="navbar-brand-box">
            <a className="logo logo-dark"><span className="logo-sm">
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path d="M8.5,18l3.5,4l3.5-4H19c1.103,0,2-0.897,2-2V4c0-1.103-0.897-2-2-2H5C3.897,2,3,2.897,3,4v12c0,1.103,0.897,2,2,2H8.5z M7,7h10v2H7V7z M7,11h7v2H7V11z"></path></svg>
            </span>
            </a>
            <button className="logo logo-light">
              <span className="logo-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path d="M8.5,18l3.5,4l3.5-4H19c1.103,0,2-0.897,2-2V4c0-1.103-0.897-2-2-2H5C3.897,2,3,2.897,3,4v12c0,1.103,0.897,2,2,2H8.5z M7,7h10v2H7V7z M7,11h7v2H7V11z"></path></svg>
              </span>
            </button>
          </div>
          <div className="flex-lg-column my-0 sidemenu-navigation">
            <ul role="tablist" className="side-menu-nav nav nav-pills">
              <li id="pills-chat-tab-container" className="nav-item">
                <button onClick={play} id="pills-chat-tab" role="tab" className="nav-link active">
                  <i className="bx bx-conversation"></i>
                </button>
              </li>
              <li id="color-mode" className="mt-auto nav-item">
                <button onClick={HandleLogout} className="nav-link light-dark nav-link">
                  <i className="bx bx-log-out" id="moon"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="chat-leftsidebar no-select">
          <div className="tab-content">
            <div role="tabpanel" aria-labelledby="pills-chat-tab" className="tab-pane active">
              <div>
                <div className="px-4 pt-4">
                  <div className="d-flex align-items-start">
                    <div className="flex-grow-1">
                      <h4 className="mb-4">
                        Atendimentos
                        <button
                          style={{ borderRadius: '100%', width: 30 }}
                          onClick={(e) => { setShowModalAbrirChamadoWhatsapp(true); populaClienteTelefone(); }}
                          className="btn btn-primary btn-sm ms-3">
                          +
                        </button>
                      </h4>
                    </div>
                  </div>
                </div>
                <div data-simplebar="init" className="chat-room-list">
                  <div className="simplebar-wrapper spbw">
                    <div className="simplebar-height-auto-observer-wrapper">
                      <div className="simplebar-height-auto-observer"></div>
                    </div>
                    <div className="simplebar-mask">
                      <div className="simplebar-offset spof">
                        <div className="simplebar-content-wrapper spcw" tabIndex="0" role="region" aria-label="scrollable content">
                          <div className="simplebar-content spbc">
                            <div className="d-flex align-items-center px-4 mt-2">
                              <div className="flex-grow-1">
                                <div className="row">
                                  <div className="col-md-10">
                                    <h4 className="mb-0 font-size-11 text-muted text-uppercase">
                                      Em Aberto
                                    </h4>
                                  </div>
                                  <div onClick={(e) => { hideShowAbertoSeta(e) }} className="col-md-2" style={{ textAlign: "right" }}>
                                    <a className="mb-0 font-size-11 text-muted text-uppercase">
                                      <i id='aberto-seta' className={aberto_seta_class}></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={aberto_seta}>
                              <div className='chat-message-list'>
                                <ul className="list-unstyled chat-list chat-user-list">
                                  {aberto.filter(item => (item.dataencerramento == null) && (item.dataatendido == null)).map(filteredItem => (
                                    <LeftBar_Item
                                      user={user}
                                      item={filteredItem}
                                      key={filteredItem.id}
                                      status="away"
                                      handlerClickedItem={handlerClickedItem}
                                    />
                                  ))}
                                </ul>
                              </div>
                            </div>
                            <div className="d-flex align-items-center px-4 mt-2">
                              <div className="flex-grow-1">
                                <div className="row">
                                  <div className="col-md-10">
                                    <h4 className="mb-0 font-size-11 text-muted text-uppercase">
                                      Em Atendimento
                                    </h4>
                                  </div>
                                  <div onClick={(e) => { hideShowematendimentoSeta(e) }} className="col-md-2" style={{ textAlign: "right" }}>
                                    <a className="mb-0 font-size-11 text-muted text-uppercase">
                                      <i id='ematendimento-seta' className={ematendimento_seta_class}></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={ematendimento_seta}>
                              <div className="chat-message-list">
                                <ul className="list-unstyled chat-list chat-user-list">
                                  {aberto.filter(item => (item.tecnicoId == user.id) && !(item.dataatendido == null) && (item.dataencerramento == null))
                                    .map(item => (
                                      <LeftBar_Item
                                        key={item.id}
                                        item={item}
                                        user={user}
                                        status={'online'} // Define um valor padrão se naoLidas[item.id] for falsy
                                        naoLidas={naoLidas[item.id]} // Define 0 como padrão se naoLidas[item.id] for null ou undefined
                                        handlerClickedItem={handlerClickedItem}
                                        play={play}
                                        setNaoLidas={setNaoLidas}
                                      />
                                    ))}
                                </ul>
                              </div>
                            </div>
                            <div className="d-flex align-items-center px-4 mt-2 mb-1">
                              <div className="flex-grow-1">
                                <div className="row">
                                  <div className="col-md-10">
                                    <h4 className="mb-0 font-size-11 text-muted text-uppercase">
                                      Em Atendimento<i> outros...</i>
                                    </h4>
                                  </div>
                                  <div onClick={(e) => { hideShowematendimentoOSeta(e) }} className="col-md-2" style={{ textAlign: "right" }}>
                                    <a className="mb-0 font-size-11 text-muted text-uppercase">
                                      <i id='ematendimentoo-seta' className={ematendimentoo_seta_class}></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={ematendimentoo_seta}>
                              <div className="chat-message-list">
                                <ul className="list-unstyled chat-list chat-user-list">
                                  {aberto.filter(item => !(item.tecnicoId == user.id) && !(item.dataatendido == null) && (item.dataencerramento == null)).map(item => (
                                    <LeftBar_Item
                                      key={item.id}
                                      item={item}
                                      user={user}
                                      status={'online'} // Define um valor padrão se naoLidas[item.id] for falsy
                                      naoLidas={naoLidas[item.id]} // Define 0 como padrão se naoLidas[item.id] for null ou undefined
                                      handlerClickedItem={handlerClickedItem}
                                      play={play}
                                      setNaoLidas={setNaoLidas}
                                    />
                                  ))}
                                </ul>
                              </div>
                            </div>
                            <div className="d-flex align-items-center px-4 mt-2 mb-1">
                              <div className="flex-grow-1">
                                <div className="row">
                                  <div className="col-md-10">
                                    <h4 className="mb-0 font-size-11 text-muted text-uppercase">
                                      Finalizado
                                    </h4>
                                  </div>
                                  <div onClick={(e) => { hideShowFinalizadoSeta(e) }} className="col-md-2" style={{ textAlign: "right" }}>
                                    <a className="mb-0 font-size-11 text-muted text-uppercase">
                                      <i id='finalizado-seta' className={finalizado_seta_class}></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={finalizado_seta}>
                              <div className="chat-message-list">
                                <ul className="list-unstyled chat-list chat-user-list">
                                  {aberto.filter(item => !(item.dataencerramento == null)).map(filteredItem => (
                                    <LeftBar_Item
                                      user={user}
                                      item={filteredItem}
                                      key={filteredItem.id}
                                      status="offline"
                                      handlerClickedItem={handlerClickedItem}
                                    />
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="simplebar-placeholder sppl"></div>
                  </div>
                  <div className="simplebar-track simplebar-horizontal sptr">
                    <div className="simplebar-scrollbar spcr"></div>
                  </div>
                  <div className="simplebar-track simplebar-vertical spv">
                    <div className="simplebar-scrollbar spbc2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {// PARA OCULTAR A TELA DE BEM VINDO CENTRALIZADA E ADICIONAR O CORPO DO CHAT INSERIR O CLASS user-chat-show NA DIV ABAIXO
        }
        <div className="user-chat w-100 overflow-hidden" id="user-chat">
          <div className="user-chat-overlay" id="user-chat-overlay"></div>
          <div className="chat-welcome-section">
            <div className="w-100 justify-content-center row">
              <div xxl="5" className="col-md-7">
                <div className="p-4 text-center">
                  <div className="avatar-xl mx-auto mb-4">
                    <div className="avatar-title bg-soft-primary rounded-circle">
                      <i className="bx bxs-message-alt-detail display-4 text-primary m-0"></i>
                    </div>
                  </div>
                  <h4>Bem-vindo ao Suporte EasySoft</h4>
                  <p className="text-muted mb-4">Inicie um atendimento pelo menu lateral esquerdo</p>
                </div>
              </div>
            </div>
          </div>
          <div className="chat-content d-lg-flex">
            <div className="w-100 overflow-hidden position-relative">
              <div className="p-3 p-lg-4 user-chat-topbar">
                <div className="align-items-center row">
                  <div className="col-12 col-sm-10">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 d-block d-lg-none me-2">
                        <a onClick={handlerRemoveChat} className="user-chat-remove text-muted font-size-24 p-2" >
                          <i className="bx bx-chevron-left align-middle"></i>
                        </a>
                      </div>
                      <div className="flex-grow-1 overflow-hidden">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0 chat-user-img align-self-center me-3 ms-0 online">
                            <span className="user-status bg-success"></span>
                          </div>
                          <div className="flex-grow-1 overflow-hidden">
                            <h6 className="text-truncate mb-0 font-size-18">
                              <a className="user-profile-show text-reset" >{clickedItem.nomecliente} - {clickedItem.operador}</a>
                              &nbsp;&nbsp;
                              {(clickedItem.idCliente === 1) &&
                                <a className="informe-cliente" onClick={handlerOpenModalCliente}><small>Informar cliente</small>&nbsp;<i className='bx bxs-user-plus' ></i></a>
                              }
                            </h6>
                            <p className="text-truncate text-muted mb-0">
                              <small>{clickedItem.status}</small>
                              <small style={{ marginLeft: '20px' }}>Cliente : {clickedItem.categoria1} {clickedItem.categoria2} {clickedItem.categoria3}</small>
                            </p>
                            <p className="text-truncate text-muted mb-0">
                              <small>Técnico : {clickedItem.tcategoria1} {clickedItem.tcategoria2} {clickedItem.tcategoria3}-</small>
                              <a className="informe-categoria" onClick={handlerOpenModalCategoria}><small>Alterar Categoria</small>&nbsp;<i className='bx bxs-category-alt'></i></a>
                              {(clickedItem.tecnicon != null) &&
                                <small>&nbsp;&nbsp;&nbsp;&nbsp;—&nbsp;&nbsp;Atribuido à <b>{clickedItem.tecnicon}</b></small>
                              }
                            </p>
                            <p className="text-truncate text-muted mb-0">
                              <small onClick={() => copiarTexto(clickedItem.idacesso)}>ID: {clickedItem.idacesso} <i className='bx bx-copy' ></i></small>
                              <small onClick={() => copiarTexto(clickedItem.senhaacesso)} style={{ marginLeft: '20px' }}>Senha : {clickedItem.senhaacesso} <i className='bx bx-copy' ></i></small>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-2 col-sm-2">
                    <ul className="list-inline user-chat-nav text-end mb-0">
                      <li className="list-inline-item">
                        <button onClick={handlerInfoPC} title="Informações do computador" type="button" aria-haspopup="true" aria-expanded="false" className="btn nav-btn dropdown-toggle btn btn-none">
                          <i className='bx bxs-info-circle finaliza-suporte'></i>
                        </button>
                      </li>
                      {(clickedItem.status === 'Finalizado') &&
                        <li className="list-inline-item">
                          <button onClick={handlerComentario} title="Comentar suporte" type="button" aria-haspopup="true" aria-expanded="false" className="btn nav-btn dropdown-toggle btn btn-none">
                            <i className='bx bxs-message-rounded-add finaliza-suporte'></i>
                          </button>
                        </li>
                      }
                      {(clickedItem.status !== 'Em Aberto') &&
                        <li className="list-inline-item">
                          <button onClick={handlerOpenModalTransferirSuporte} title="Transferir Suporte" type="button" aria-haspopup="true" aria-expanded="false" className="btn nav-btn dropdown-toggle btn btn-none">
                            <i className='bx bx-transfer-alt finaliza-suporte'></i>
                          </button>
                        </li>
                      }
                      {(clickedItem.status == 'Em Atendimento') &&
                        <li className="list-inline-item">
                          <button onClick={handlerEncerrarSuporte} title="Encerrar atendimento" type="button" aria-haspopup="true" aria-expanded="false" className="btn nav-btn dropdown-toggle btn btn-none">
                            <i className='bx bx-wifi-off finaliza-suporte'></i>
                          </button>
                        </li>
                      }
                    </ul>
                  </div>
                </div>
              </div>
              <div data-simplebar="init" className="chat-conversation p-3 p-lg-4 positin-relative">
                <div className="simplebar-wrapper">
                  <div className="simplebar-height-auto-observer-wrapper">
                    <div className="simplebar-height-auto-observer"></div>
                  </div>
                  <div className="simplebar-mask">
                    {(clickedItem.status === 'Em Aberto') &&
                      <div className="d-flex align-items-center justify-content-center h-100">
                        <button style={{ zIndex: 999 }} onClick={handlerAtribuir} type="button" className="btn btn-primary">Atribuir a mim...</button>
                      </div>
                    }
                    <div className="simplebar-offset">
                      <div className="simplebar-content-wrapper" tabIndex="0" role="region" aria-label="scrollable content">
                        <div className="simplebar-content">
                          <ul className="list-unstyled chat-conversation-list" id="chat-conversation-list">
                            {mensagens.map((mensagem) => (
                              <Item_Chat
                                key={mensagem.id}
                                item={mensagem} />
                            ))}
                            <a ref={aboutSection}></a>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simplebar-placeholder"></div>
                </div>
                <div className="simplebar-track simplebar-horizontal">
                  <div className="simplebar-scrollbar"></div>
                </div>
                <div className="simplebar-track simplebar-vertical">
                  <div className="simplebar-scrollbar"></div>
                </div>
              </div>
              {(clickedItem.status === 'Em Atendimento') &&
                <div className="chat-input-section p-3 p-lg-4">
                  <form id="chatinput-form" className="" onSubmit={(e) => { handlerEnviarMensagem(e) }}>
                    <div className="row g-0 align-items-center">
                      <div className="col">
                        <div className="position-relative">
                          <input
                            id="chat-input"
                            placeholder="Digite sua mensagem....."
                            autoComplete="off"
                            type="text"
                            className="form-control form-control-lg chat-input form-control"
                            onChange={e => { setMensagem(e.target.value) }}
                            value={mensagem} />
                        </div>
                      </div>
                      <div className="col-auto">
                        <div className="chat-input-links ms-2 gap-md-1">
                          <div className="links-list-item">
                            <button type="submit" className="btn btn-primary btn-lg chat-send waves-effect waves-light">
                              <i className="bx bxs-send align-middle"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      {// Modal observação
      }
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onSubmit={handlerEnviarComentario}>
        <Modal.Header closeButton>
          <Modal.Title>Deixe abaixo seu comentário:</Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1">
              <Form.Control onChange={(e) => { setComentario(e.target.value) }} value={(comentario == null) ? '' : comentario} autoFocus={true} as="textarea" rows={8} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Fechar
            </Button>
            <Button variant="primary" type="submit">
              Salvar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {// Modal Cliente
      }
      <Modal
        show={showModalCliente}
        onHide={handlerCloseModalCliente}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onSubmit={handlerAlterarCliente}>
        <Modal.Header closeButton>
          <Modal.Title>Selecione um cliente para inserir no atendimento</Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body>
            <Typeahead
              onChange={(selected) => {
                setSelectCliente({ selected });
              }}
              options={clientes}
              id="clientes"
              placeholder="Selecione o cliente..."
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handlerCloseModalCliente}>
              Fechar
            </Button>
            <Button variant="primary" type="submit">
              Salvar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {// Modal Categoria
      }
      <Modal
        show={showModalCategoria}
        onHide={handlerCloseModalCategoria}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onSubmit={handlerAlterarCategoria}>
        <Modal.Header closeButton>
          <Modal.Title>Selecione a categoria para alteração</Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body>
            <div className="row">
              <div className="col">
                <Typeahead
                  id="basic-typeahead"
                  labelKey="label"
                  onChange={setSelectCategoria1}
                  options={categoria1}
                  placeholder="Categoria 1"
                  selected={selectCategoria1}
                />
              </div>
              <div className="col">
                <Typeahead
                  id="basic-typeahead"
                  labelKey="label"
                  onChange={setSelectCategoria2}
                  options={categoria2}
                  disabled={categoria2Disabled}
                  placeholder="Categoria 2"
                  selected={selectCategoria2}
                />
              </div>
              <div className="col">
                <Typeahead
                  id="basic-typeahead"
                  labelKey="label"
                  disabled={categoria3Disabled}
                  onChange={setSelectCategoria3}
                  options={categoria3}
                  placeholder="Categoria 3"
                  selected={selectCategoria3}
                />
              </div>
            </div>


          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handlerCloseModalCategoria}>
              Fechar
            </Button>
            <Button disabled={disabledButtonCategoria} variant="primary" type="submit">
              Salvar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {// Modal Transferir Suporte
      }
      <Modal
        show={showModalTransferirSuporte}
        onHide={handlerCloseModalTransferirSuporte}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onSubmit={handlerTransferirSuporte}>
        <Modal.Header closeButton>
          <Modal.Title>Selecione um técnico para transferir o atendimento</Modal.Title>
          <a className="ms-3" style={{ color: "blue" }} onClick={(e) => populaTecnicos('S')}>Mostrar todos técnicos</a>
        </Modal.Header>
        <Form>
          <Modal.Body>
            <Typeahead
              onChange={(selected) => {
                setSelectTransferirSuporte({ selected });
              }}
              options={tecnicos}
              id="tecnicos"
              labelKey="label"
              placeholder="Selecione o tecnico para transferir..."
              renderMenuItemChildren={(option, props) => (
                <div>
                  {option.qtd === 0 ? (
                    <span style={{ color: 'green', marginRight: '8px' }}>🟢({option.qtd})</span>
                  ) : (
                    <span style={{ color: 'red', marginRight: '8px' }}>🔴({option.qtd})</span>
                  )}
                  {option.label}
                </div>
              )}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handlerCloseModalTransferirSuporte}>
              Fechar
            </Button>
            <Button variant="primary" type="submit">
              Transferir
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {// Modal Abrir chamado whatsapp
      }
      <Modal
        show={showModalAbrirChamadoWhatsapp}
        onHide={handlerCloseModalAbrirChamadoWhatsapp}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onSubmit={handlerAbrirChamadoWhatsapp}>
        <Modal.Header closeButton>
          <Modal.Title>Selecione o telefone que deseja entrar em contato</Modal.Title>
          <a className="ms-3" style={{ color: "blue" }} onClick={(e) => setExibeCombo(!exibeCombo)}>Digitar número</a>
        </Modal.Header>
        <Form>
          <Modal.Body>
            {exibeCombo ? (
              <Typeahead
                id="basic-typeahead"
                labelKey="label"
                onChange={setSelectClienteTelefone}
                options={clienteTelefone}
                placeholder="Pesquise a empresa que deseja entrar em contato"
                selected={selectClienteTelefone}
              />
            ) : (
              <Form.Control
                type="text"
                placeholder="Digite o número de telefone DDD+número sem espaços"
                value={numeroTelefone}
                onChange={(e) => setNumeroTelefone(e.target.value)}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handlerCloseModalAbrirChamadoWhatsapp}>
              Fechar
            </Button>
            <Button variant="primary" type="submit">
              Abrir Chamado
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default Chat;